import React from "react";

const GoogleMap = ({ extraClass }) => {
  return (
    <div className={`google-map__${extraClass}`}>
      <iframe
        title="Cairn Office Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d152527.131248068!2d-6.4959002240228925!3d53.321120001869915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670e85666b767d%3A0x3f4db56586fe5de3!2sCairn%20Construction!5e0!3m2!1sen!2sin!4v1654513326076!5m2!1sen!2sin"
        className={`map__${extraClass}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
