import React from "react";
import Layout from "@/components/layout";
import CallToActionOne from "@/components/call-to-action-one";
import Footer from "@/components/footer";
// import ServiceHome from "@/components/service-home";
// import ProjectHome from "@/components/project-home";
import ServiceTwo from "@/components/service-two";
import ContactInfos from "@/components/contact-infos";
import ServiceThree from "@/components/service-three";
import ParallaxOne from "@/components/parallax-one";
import GoogleMap from "@/components/google-map";
import TestimonialsCarousel from "@/components/testimonials-carousel";
import FeatureTabOne from "@/components/feature-tab-one";
import CallToActionTwo from "@/components/call-to-action-two";
import FeatureTwo from "@/components/feature-two";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
import SliderOne from "@/components/slider-one";
import Home from  "@/components/home";
import VideoOne from "@/components/video-one";

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home">
          <HeaderOne />
          <SliderOne />
          <CallToActionOne extraClassName="ready_2" buttonClass="red_bg" />
          {/* <Home/> */}
          {/* <ServiceTwo/> */}
          {/* <ServiceHome /> */}
          <VideoOne />
          <FeatureTwo />
          {/* <TestimonialsCarousel /> */}
          {/* <FeatureTabOne /> */}
          <ParallaxOne />
          {/* <ServiceThree /> */}
          {/* <ProjectHome /> */}
          <GoogleMap extraClass="contact-page" />
          {/* <CallToActionTwo /> */}
          <ContactInfos />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
